.sportzchain-card-main{
    margin: 91px 0 0 0;
    color: #FFF;
}
.sportzchain-card-main .display-row {
    margin-left: -15px;
    margin-right: -15px;
}
.sportzchain-card-main .display-row > * {
    padding: 0px 15px;
}
.sportzchain-card{

    background: #101010;
    border: 1px solid #101010;

    padding: 25px;
    border-radius: 10px;
    text-align: center;
    height: 100%;
}
.sportzchain-img-sec .sportzchain-card-img{
    width: 219px;
    height: 163px;
    object-fit: contain;
}
.sportzchain-card .sportz-card-title{
font-size: 28px;
line-height: 34px;
color: #FFF;
font-family: 'poppins-semibold';
margin: 35px 0 21px 0;
}
.sportzchain-card .sportz-card-subtitle{
font-size: 17px;
line-height: 29px;
color: #8e8e8e;
font-family: 'poppins-regular';
}
.sportzchain-card:hover{
    border-color: #ff4d1d;
}

.api-rate-amount {
    text-align: left;
}

.rewards-text-box .rewards-mini-box{
    display: flex;
    justify-content:space-around;
    margin-top: 20px;
    
}

.rewards-text-box .rewards-mini-box p{
    font-size: 18px;
    line-height: 26px;
    font-family: 'poppins-medium';
} 

.rewards-text-box{
    color: #535353;
    margin-bottom: 15px;
}

.rewards-text-box p{
    font-size: 18px;
    line-height: 26px;
    font-family: 'poppins-medium';
}

.rewards-text-box .rewards-mini-box span{
    
    color: #FFF;
  }
  .rewards-text-box .api-rate-text span{
    color: #FFF;
  }

