.staking-main-sec {
  padding: 96px 0 80px 0;
  position: relative;
}

.home-wrapper .staking-main-sec .home-title::before {
  right: -10px;
  top: 2px;
  left: initial;
}

.home-wrapper .staking-main-sec .home-title::after {
  left: -10px;
  bottom: 17px;
  right: initial;
}

.staking-main-sec::before {
  top: 0;
}

.staking-card {
  padding-left: 55px;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  background: #0a0a0a;
  color: #fff;
  min-height: 256px;
  border-radius: 10px;
  transition: 0.3s ease;
  border: 1px solid #0a0a0a;
  box-shadow: -5px 4px 4px #f3491b66
}

.staking-card:hover {
  border-color: #f3491b;
  box-shadow: -5px 4px 4px #f3491b
}

.staking-card .staking-card-title {
  font-size: 42px;
  line-height: 45px;
  font-family: "poppins-semibold";
  color: #fff;
  margin: 0 0 35px 0;
}

.staking-card .staking-card-title span {
  color: #f3491b;
  text-transform: uppercase;
  text-align: center;
}

.stacking-card-days {
  padding-left: 55px;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  background: #0a0a0a;
  color: #fff;
  min-height: 256px;
  border-radius: 10px;
  transition: 0.3s ease;
  margin-top: 50px;
  box-shadow: -5px 4px 4px #f3491b66
}

.stacking-card-days:hover {
  border-color: #f3491b;
  box-shadow: -5px 4px 4px #f3491b
}

.staking-card-days-navigate {
  cursor: pointer;
}

.stacking-card-days .staking-card-days-title {
  font-size: 42px;
  line-height: 25px;
  font-family: "poppins-semibold";
  color: #fff;
  margin: 0 0 35px 0;
  text-decoration: none;
}

.stacking-card-days .staking-card-days-title span {
  color: #f3491b;
  text-transform: uppercase;
  text-decoration: none;
}

.stacking-card-days .staking-card-days-subtitle {
  font-size: 28px;
  line-height: 35px;
  font-family: "poppins-medium";
  color: #fff;
  margin: 0 0 5px 0;
  text-decoration: none;
}

.stacking-card-days .staking-card-subtitle {
  font-size: 28px;
  line-height: 30px;
  font-family: "poppins-medium";
  color: #fff;
}

.spn-wrapper {
  margin-top: 85px;
}

.spn-token-wrapper {
  background: #0a0a0a;
  padding: 57px 66px;
  margin: 44px 0;
  border-radius: 10px;
}

.spn-btn-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  flex-wrap: wrap;
}

.spn-days {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 15px;
}

.spn-days .stake-spn-img {
  width: 35px;
  height: 48px;
  object-fit: contain;
}

.spn-days .stake-spn-text {
  font-size: 36px;
  font-family: "poppins-semibold";
  color: #fff;
  margin: 0 28px 0 15px;
}

.spn-days .days-btn {
  background: linear-gradient(45deg, #629fe1, #9dcaf5);
  padding: 12.5px 37px;
  color: #fff;
  font-size: 18px;
  font-family: "poppins-bold";
  border-radius: 8px;
  cursor: auto;
}

.api-rate-main {
  display: flex;
  justify-content: space-between;
  margin: 38px 0 0 0;
  gap: 30px;
}

.api-rate-amount .api-rate-text {
  color: #535353;
  font-size: 22px;
  line-height: 26px;
  font-family: "poppins-medium";
  margin: 0 0 32px 0;
}

.api-rate-amount .api-rate-text span {
  color: #fff;
}

.api-rate-value .stake-spn-text {
  color: #fff;
  font-size: 28px;
  font-family: "poppins-semibold";
  text-align: right;
}

.api-rate-value {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.api-rate-value .api-rate-percent {
  color: #f3491b;
  font-size: 58px;
  font-family: "poppins-semibold";
  text-align: right;
}

.spn-token-wrapper .what-is-title {
  color: #fff;
  font-size: 28px;
  font-family: "poppins-medium";
  margin: 32px 0;
}

.spn-token-wrapper .what-is-ans {
  color: #cccccc;
  font-size: 21px;
  font-family: "poppins-light";
  line-height: 39px;
}

.api-rate-value .api-rate-input {
  color: white;
}

.stake-input-field {
  width: 200px;
}

.stake-input-field .api-rate-input input {
  border-radius: 10px;
  border: 1px solid #808080;
  outline: none;
  color: #fff;
}

.stake-input-field .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border-radius: 10px;
  border: 1px solid #808080;
  color: #fff;
  border: 1px solid #808080;
}

.api-rate-value .stake-token-amount {
  font-size: 24px;
  margin-bottom: 15px;
}

.border-bg-btn-user .border-bg-btn .btn-label,
.border-bg-btn-user .border-bg-btn .btn-label:hover {
  background: linear-gradient(45deg, #fe5f25, #fb8d3a);
  font-size: 16px !important;
  line-height: 22px;
  width: 150px !important;
}

.border-bg-btn-user {
  margin-top: 20px;
  display: flex;
  justify-content: end;
}

.modal-delete .nfc-inner-modal {
  padding: 20px 24px 40px 24px !important;
  max-width: 670px !important;
}

.delete-modal-title span {
  font-size: 50px;
}

.nfc-btn-sec .modal-delete-btn,
.nfc-btn-sec .modal-delete-btn:hover {
  max-width: max-content !important;
  font-weight: 600;
}

.nfc-inner-modal .delete-modal-para {
  max-width: max-content !important;
  font-size: 24px;
  margin: 20px auto !important;
}

.delete-modal-para span {
  font-weight: 700;
}

.nfc-inner-modal .user-growth-img {
  width: 150px;
  height: 150px;
}

.nfc-inner-modal .modal-circle-main {
  text-align: center;
}

.nfc-inner-modal:focus-visible {
  outline: none;
}

.nfc-inner-modal .cancel-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 15px;
}

.grid-column .staking-card-coming {
  padding-left: 55px;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
}

.staking-card-coming {
  width: 50px;
  height: 50px;
}

.staking-card .coming-soon-title {
  font-size: 45px;
  line-height: 45px;
  font-family: "poppins-semibold";
  color: #f3491b;
  text-transform: uppercase;
  text-align: center;
}

.staking-card .staking-card-title span {
  color: #f3491b;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-staking .staking-card-title .coming-text {
  text-align: center !important;
}

.grid-column .cart-staking {
  padding-left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  border-radius: 10px;
  background-color: #000000 !important;
  border: none;
  box-shadow: none;
  height: 100%;

}

.home-container .coming-soon-main-box {
  margin-top: 80px;

}

.coming-soon-wrapper {
  margin-top: 60px;
}