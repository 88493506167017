@media (max-width: 1023px) {
    .home-container-stake-main .smart-staking-wrapper .spn-token-wrapper {
        padding: 80px 0;
    }
}

@media (max-width: 767px) {
    .home-container-stake-main .smart-staking-wrapper .spn-token-wrapper {
        padding: 40px 0;
    }
    .home-container-stake-main .smart-staking-wrapper .spn-token-wrapper {
        padding: 30px 0;
    }
}