.home-wrapper{
    background: #000;
    height: 100%;
}
.border-bg-btn .btn-label,
.border-bg-btn .btn-label:hover {
  background: linear-gradient(45deg, #fe5f25, #fb8d3a);
  border-radius: 8px;
  font-size: 26px;
  line-height: 22px;
  font-family: "poppins-semibold";
  height: 62px;
  width: 255px;
  text-transform: capitalize;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.border-bg-btn  .br-bg-btn{
    border: 1px solid #808080;
    background: #1a1a1a;
    padding: 9px;
    border-radius: 8px;
}
.hero-content .border-bg-btn {
    margin-top: 60px;
}
.hero-main-sec{
    position: relative;
}
.hero-main-sec::before,
.staking-main-sec::before{
    content: '';
    background-image: url('../../../../assets//images//png/leftBgImg.png');
    width: 287px;
    height: 570px;
    position: absolute;
    left: 0;
    top: -138px;
    pointer-events: none;
}

.sportzchain-main-sec{
    background: #030303;
    padding: 67px 0 116px 0;
}
.home-container{
    max-width: 1150px;
    padding: 0 15px;
    margin: auto;
  }
  .home-wrapper .home-title{
    font-size: 38px;
    line-height: 91px;
    color: #FFF;
    font-family: 'poppins-semibold';
    text-align: center;
    position: relative;
  }
  .home-wrapper .home-title span{
  color: #ff4d1d;
  text-transform: uppercase;
  }
  .home-title-sec{
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  .home-wrapper .home-title::before{
    content: '';
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background: #ff4d1d;
    opacity: 0.2;
    position: absolute;
    left: -10px;
    top: 5px;
  }
  .home-wrapper .home-title::after{
    content: '';
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #ff4d1d;
    opacity: 0.2;
    position: absolute;
    right: -10px;
    bottom: 15px;
  }
  .home-wrapper .sub-title{
    font-size: 22px;
    line-height: 31px;
    color: #8e8e8e;
    font-family: 'poppins-regular';
  }
  