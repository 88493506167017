.hero-wrapper {
    padding: 150px 0 172px 0;
    background: #000;
}


.hero-content .hero-title {
    font-size: 64px;
    line-height: 92px;
    color: #FFF;
    font-family: "poppins-medium";
}

.hero-content .hero-sub-title {
    font-size: 26px;
    line-height: 45px;
    color: #696969;
    font-family: 'poppins-regular';
}

.hero-img-sec .home-img {
    width: 100%;
    height: auto;
    max-width: 580px;
    object-fit: contain;
    animation: floating 3s infinite;
}

@keyframes floating {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(20px);
    }

    100% {
        transform: translateY(0px);
    }
}

.delete-modal-inner-main .cancel-btn {
    cursor: pointer;
}