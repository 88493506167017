@media (max-width: 1023px) {
    .sportzchain-main-sec {
        background: #030303;
        padding: 80px 0;
    }

    .home-wrapper .home-title {
        font-size: 40px;
        line-height: 60px;
        margin-bottom: 30px;
    }

    .sportzchain-card-main {
        margin: 60px 0 0 0;
    }
}

@media (max-width: 767px) {
    .home-wrapper .home-title {
        font-size: 30px;
        line-height: 50px;
    }

    .home-wrapper .sub-title {
        font-size: 20px;
        line-height: 26px;
    }

    .sportzchain-main-sec {
        padding: 40px 0;
    }
}