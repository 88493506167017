.stake-detail-wrapper {
  padding: 44px 0;
}
.staking-btn-sec {
  display: flex;
  align-items: center;
}
.staking-btn-sec .view-detail-link {
  color: #fff;
  font-size: 21px;
  font-family: "poppins-medium";
  text-decoration: underline;
  margin-right: 30px;
  cursor: pointer;
}

.user-modal-set {
  width: 100% !important;
  text-align: center;
  margin-top: 21px;
}


.primary-btn-main .primary-btn.add-game-btn,
.primary-btn-main .primary-btn.add-game-btn:hover {
  background: #ff4d1d;
  color: #fff;
  font-size: 17px;
  font-family: "poppins-medium";
  padding: 10px 15px;
  height: 55px;
}

.primary-btn-main .primary-btn.add-game-btn img {
  width: 25px;
  height: 25px;
  margin: 0 10px 0 0;
  box-shadow: 0px 6px 20px #841c00;
  border-radius: 50%;
}

.gray-btn-main .gray-btn,
.gray-btn-main .gray-btn:hover {
  min-width: 30px;
  min-height: 30px;
  border-radius: 5px;
  background: #393939;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px 0 0;
  cursor: pointer;
}

.action-btn-img {
  width: 16px;
  height: 21px;
}

.gray-btn-main {
  display: flex;
  align-items: center;
}

.home-container-stake-main {
  background: #0a0a0a;
}

.home-container-stake-main .smart-staking-wrapper .spn-token-wrapper {
  background: #0a0a0a;
  padding: 117px 0;
  margin: 0px;
  border-radius: 0px;
}

.error-message {
  color: red;
  font-size: 16px !important;
  margin-top: 3px !important;
  margin-left: 2px !important;
}

.delete-modal-inner-main .unstake-heading-main-box {
  display: flex;
  justify-content: space-between;
  font-size: 21px;
  line-height: 28px;
  color: #fff;
  font-family: "poppins-medium";
}

.delete-modal-inner-main .unstake-head-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.unstake-model-main-box {
  width: 625px;
  height: auto;
  padding: 23px;
  border-radius: 13px;
  background: #1a1a1a;
}
.modal-unstake .MuiModal-root{
    border: none;
}
.unstake-heading-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 19px;
}
.unstake-heading-box .unstake-head-icon {
  width: 37.23px;
  height: 37.23px;
}

.unstake-heading-box .heading {
  color: #fff;
  text-align: center;
  font-family: "Poppins-regular";
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; 
  letter-spacing: -0.233px;
}
.divider-box .divider {
  width: 579.132px;
  height: 1px;
  background: #3e3e3e;
  display: flex;
  justify-content: center;
  align-items: center;

}
.unstake-lock-period-box {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.unstake-lock-period-box .unstake-lock-period-text {
  color: #fff;
  font-family: "Poppins-regular";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px; 
  letter-spacing: -0.233px;
}
.unstake-lock-period-box .unstake-lock-period-day {
  color: #fff;
  text-align: right;
  font-family: "Poppins-regular";
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 38px; 
  letter-spacing: -0.233px;
}
.modal-unstake {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-unstake .model-unstake-loader{
  width: 625px;
}

.unstake-model-main-box:focus-visible{
   outline: none;
}

.unstake-lock-period-box .reward-address-pop-up{
  color: #fff;
  text-align: right;
  font-family: "Poppins-regular";
  font-size: 19px;
  font-style: normal;
  font-weight: 300;
  width: 240px;
  line-height: 22px;
  letter-spacing: -0.233px;
  word-break: break-all;
}

.stake-input-field .unstake-field-error {
  display: flex;
justify-content: center;
text-align: start;
}

.unstake-field-error .error-message{
  width: 50%;
}
.user-stake-btn-sec{
  display: flex;
  justify-content: space-between;
}
.mt-0{
  margin-top: 0;
}