@media (max-width: 1023px) {
    .header-wrapper .mobile-menu-btn {
        display: flex;
        color: #fff;
        padding: 0;
        min-width: unset;
        margin-left: 15px;
    }

    .header-wrapper .mobile-menu-btn svg {
        width: 30px;
        height: 30px;
    }

    .header-wrapper .mobile-menu-btn:after {
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        background-color: rgba(0, 0, 0, .2);
        content: "";
        height: 100%;
        opacity: 0;
        overflow: hidden;
        position: fixed;
        right: 0;
        top: 0;
        transition: all .3s ease-in-out;
        visibility: hidden;
        width: 100%;
        z-index: 3;
    }

    .header-wrapper .mobile-nav-open .mobile-menu-btn:after {
        opacity: 1;
        overflow: auto;
        visibility: visible;
    }

    .header-ul .mobile-nav-close {
        align-items: center;
        background-color: #fff;
        border-radius: 50%;
        color: #191510;
        cursor: pointer;
        display: flex;
        font-weight: 700;
        justify-content: center;
        max-height: 22px;
        max-width: 22px;
        min-height: 22px;
        min-width: 22px;
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 12px;
        line-height: 0;
    }

    .home-main .header-ul {
        background: #191510;
        color: #fff;
        height: 100vh;
        max-width: 340px;
        position: fixed;
        right: -100%;
        top: 0;
        transition: all .5s ease-in-out;
        width: 100%;
        z-index: 4;
        flex-direction: column;
        flex-direction: column;
        gap: 30px;
        padding: 40px 20px;
    }

    .home-main .mobile-nav-open .header-ul {
        right: 0;
    }

    .header-ul .header-li {
        margin: 0;
    }

    .mobile-navigation .mobile-nav-body {
        height: calc(100% - 69.86px);
        overflow: hidden;
        padding: 0 8px 10px;
    }

    .footer-main {
        flex-direction: column;
    }

    .copyright-sec {
        padding: 30px 0;
    }

    .copyright-main .copyright-text {
        font-size: 16px;
        line-height: 20px;
    }
}

@media (max-width: 767px) {
    .home-main {
        padding: 15px 0;
    }

    .home-logo {
        width: 150px;
        height: auto;
    }

    .meta-wallet .metamask-btn button{
        font-size: 12px;
        line-height: 16px;
    }

    .header-list .meta-wallet .metamask-btn {
        margin-left: 10px;
        border-radius: 6px;
        font-size: 12px;
        line-height: 16px;
        padding: 0;
        min-width: unset;
    }

    .meta-wallet .metamask-img {
        width: 24px;
    }

    .footer-privacy .footer-logo{
        width: 180px;
        height: auto;
    }

    .footer-social-link .social-link {
        width: 40px;
        height: 40px;
    }

    .footer-social-link .social-link img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

@media (max-width: 574px) {
    .home-logo {
        width: 120px;
    }

    .home-main .header-ul {
        max-width: 280px;
    }

    .header-ul .header-li {
        padding: 8px 0px;
    }

    .header-ul .header-li.active::after {
        height: 2px;
    }

    .header-li a {
        font-size: 16px;
        line-height: 22px;
    }

    .footer-social-link-sec .social-link-text {
        font-size: 18px;
        line-height: 28px;
        margin: 30px 0 0 0;
    }
}