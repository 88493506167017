@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .user-list-table .user-list-res {
    min-width: 1400px;
  }

  .game-upload-logo-wrapper {
    width: 33%;
  }
}

@media (max-width: 767px) {
  .delete-modal-inner-main .delete-modal-title {
    color: #ffffff;
    font-size: 20px;
    line-height: 26px;
    font-family: "Poppins-Medium";
    text-align: center;
    margin: 20px 0px 16px;
  }
}