@media (max-width: 1199px) {
    .hero-wrapper {
        padding: 80px 0;
    }
}

@media (max-width: 767px) {
    .hero-content .hero-title {
        font-size: 40px;
        line-height: 47px;
        margin-bottom: 20px;
    }

    .hero-content .hero-sub-title {
        font-size: 20px;
        line-height: 26px;
    }
}

@media (max-width: 767px) {
    .hero-wrapper {
        padding: 40px 0;
    }
    .hero-content .hero-title {
        font-size: 30px;
        line-height: 37px;
    }
}