@media (max-width: 1023px) {
  .staking-main-sec {
    padding: 80px 0;
  }
}

@media (max-width: 767px) {
  .staking-main-sec {
    padding: 40px 0;
  }

  .spn-wrapper {
    margin-top: 60px;
  }

  .staking-card .coming-soon-title {
    font-size: 30px;
    line-height: 37px;
  }

  .staking-card {
    padding-left: 30px;
  }

  .api-rate-main {
    flex-direction: column-reverse;
    margin-top: 20px;
  }

  .api-rate-value {
    align-items: flex-start;
  }

  .spn-days .stake-spn-text {
    font-size: 24px;
  }

  .spn-days .days-btn {
    padding: 10px 20px;
    font-size: 16px;
  }

  .border-bg-btn .btn-label,
  .border-bg-btn .btn-label:hover {
    font-size: 20px;
    line-height: 26px;
    height: auto;
    width: auto;
    padding: 10px 20px;
  }

  .nfc-inner-modal .delete-modal-para {
    font-size: 20px;
  }

  .nfc-inner-modal .user-growth-img {
    width: 100px;
    height: 100px;
  }
  .cart-staking{
    min-height: auto;
    padding-top: 40px;
  }
}

@media (max-width: 574px) {
  .api-rate-amount .api-rate-text {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 15px;
  }

  .spn-token-wrapper .what-is-title {
    font-size: 24px;
    line-height: 30px;
    margin: 30px 0 15px;
  }

  .spn-token-wrapper .what-is-ans {
    font-size: 18px;
    line-height: 28px;
  }
}